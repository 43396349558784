require('./bootstrap');

import $ from 'jquery';
window.$ = window.jQuery = $;
import slickSlider from 'slick-slider';


$(document).ready(function () {

    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) {
        $('body').addClass('windows-7')
    }
});

// show modal only once per visit
$(document).ready(function () {
    var is_modal_show = sessionStorage.getItem('alreadyShow');
    if (is_modal_show != 'alredy shown') {
        $('.ads-popup').addClass('d-xs-flex');
        $('.mobile-popup-background').addClass('d-xs-flex');
        $('.ads-popup').addClass('opened');
        sessionStorage.setItem('alreadyShow', 'alredy shown');
    } else {
        $('.mobile-popup-background').hide();
    }
});


//slider
$(function () {
    $('.main-slider').slick({
        infinite: true,
        arrow: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    prevArrow: false,
                    nextArrow: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                }
            },
        ]
    });
    $('.second-slider').slick({
        arrow: false,
        dots: true,
        infinite: false,
        rows: 2,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1
    });
    $('.detailed-image-slider').slick({
        arrow: true,
        dots: true,
        infinite: false,
        rows: 1,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1
    });
    $('.show-blade').slick({
        infinite: false,
        dots: false,
        arrow: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1680,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    prevArrow: false,
                    nextArrow: false,
                    centerPadding: '20%',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    });
    $('.popular-news').slick({
        infinite: false,
        dots: false,
        arrow: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1680,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    prevArrow: false,
                    nextArrow: false,
                    centerPadding: '20%',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    });
    setTimeout(function () {
        $('.favourite-slider').slick({
            infinite: false,
            arrow: true,
            dots: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        });
    }, 500);
});
//slider

// popup socialog
$(document).ready(function () {
    $('.socialog-popup').hide();
    $(".socialog-result").click(function () {
        var socialogId = $(this).attr('question_id');
        $('#' + socialogId).addClass('popup-show')
        $('.popup-background').addClass('open-popup');
        $('body').addClass('unscrolled-fixed');
    });
    $(".close-popup").click(function () {
        $('.socialog-popup').removeClass('popup-show');
        $('.popup-background').removeClass("open-popup");
        $('body').removeClass('unscrolled-fixed');

    });
    $(".socialog-close-button").click(function () {
        $(this).parent().hide();
        $('.socialog-popup-background').addClass('d-none');
        $('.socialog-popup-background').removeClass('d-flex d-xs-flex');
    });
    $(".socialog-result-close-button").click(function () {
        $(this).parent().parent().hide();
        $('.socialog-popup').removeClass('popup-show');
        $('.popup-background').removeClass('open-popup');
    });
});








// bodyze klikis dros gakres popup (socialogis)
// $(document).on('click', function (e) {
//     if ($('.socialog-popup').hasClass("popup-show")) {
//         var socialogInfo = $(".socialog-result");
//         var socialogPopup = $(".socialog-popup");
//         var socialogPopupBackground = $(".popup-background");
//         if (!$(e.target).closest(socialogInfo).length) {
//             socialogPopup.removeClass("popup-show");
//             socialogPopupBackground.removeClass("open-popup");
//         }
//     }
// });
// popup socialog

// ads
// ads size and left position
$(window).on('resize', function () {
    //container fluid size
    var fluid = $('.container-fluid').width();
    //container size
    var container = $('.container').width();
    //container fluid - container
    var fullWidth = fluid - container;
    // banner size
    var size = fullWidth / 2 - 20 - 30;
    // position
    var position = (fullWidth - size) / 8;

    //alerts
    var alertsPosition = fullWidth / 2

    // banner positions
    $(".left-banner").css({ left: position, });
    $(".right-banner").css({ right: position, });
    $('.space-banner').width(size);
    $('.main-notification').css({ right: alertsPosition, });
});
// responsive resize banner width
$(document).ready(function () {
    $(window).trigger('resize');
});

$(window).resize(function (e) {
    if ($(window).width() >= 767) {
        $(window).bind('scroll', function () {
            var headerInfo = $('.header-info').outerHeight();
            if ($(window).scrollTop() > headerInfo) {
                $('.navbar').addClass('fixed');
                $('.header-info').addClass('mb-70px');
                $('.space-banner').addClass('fixed-ads');
            }
            if ($(window).scrollTop() < headerInfo) {
                $('.navbar').removeClass('fixed');
                $('.space-banner').removeClass('fixed-ads');
                $('.header-info').removeClass('mb-70px');
            }
        });
    }
    else {
        $(window).bind('scroll', function () {
            if ($(window).scrollTop() > 0) {
                $('.navbar').addClass('fixed');
                $('.main-content').addClass('mt-70px')
            }
        });
    }
});


// navbar and banner scroll down


$(".user-name").click(function () {
    $(".profile-popup").toggleClass("d-block");
});

$(".registration-information").click(function () {
    $(".registration-popup").toggleClass("d-block");
});

// tooltip activatior
$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip({
        'delay': { show: 400, hide: 200 }
    });
});




// parolze dacherisas gvichens parols
$(".show-password").click(function () {
    $('.show-password').addClass("d-none");
    $('.hide-password').removeClass("d-none");
    $(".register-input").attr('type', 'text');
});

$(".hide-password").click(function () {
    $('.show-password').removeClass("d-none");
    $('.hide-password').addClass("d-none");
    $(".register-input").attr('type', 'password');
});



// searchis tabi
$(".search-toggle").click(function () {
    $('.search-input').addClass('d-flex');
    $('.search').addClass('search-active');
    $('.search-toggle').addClass('d-none');
    $('.navbar-toggler').addClass('d-none');
    $('.navbar-collapse').addClass("navbar-hide");
    $('.search-close').removeClass('d-none');
    $('.live-button').removeClass('d-xs-flex d-sm-flex');
    setTimeout(function () {
        $('.search-toggle').addClass('search-trigger');
    }, 1000);
});
//search close button
$(".search-close").click(function () {
    $('.search').removeClass('search-active');
    $('.search-input').removeClass('d-flex');
    $('.search-toggle').removeClass('search-trigger');
    $(this).addClass('d-none');
    // timeout for navbar show
    setTimeout(function () {
        $('.navbar-collapse').removeClass("navbar-hide");
        $('.search-toggle').removeClass('d-none');
        $('.navbar-toggler').removeClass('d-none');
        $('.live-button').addClass('d-xs-flex d-sm-flex');
    }, 1000);
});

// search trigger
$("body").on('click', '.search-trigger', function () {
    $(".search-active").submit();
});

$(".navbar-toggler").click(function () {
    $('.search').toggleClass('d-none')
});

// burger menuze "x" gamochena da "x" ze dacherisas menus akecva
$(".navbar-toggler-icon").click(function () {
    $('.navbar-closer-icon').removeClass('d-xs-none');
    $('.navbar-closer-icon').addClass('d-flex');
    $('.navbar-toggler-icon').removeClass('d-flex');
    $('.navbar-toggler-icon').addClass('d-xs-none');
});

$(".navbar-closer-icon").click(function () {
    $('.navbar-closer-icon').addClass('d-xs-none');
    $('.navbar-closer-icon').removeClass('d-flex');
    $('.navbar-toggler-icon').addClass('d-flex');
    $('.navbar-toggler-icon').removeClass('d-xs-none');
});
$(".burger-menu").click(function () {
    if ($('.navbar-collapse').css('display') == 'block') {
        $('body').removeClass('unscrolled-fixed');
    }
    else {
        $('body').addClass('unscrolled-fixed');
    };
});
// noficationis chakecva backgroundze dacherisas
$(".nofication-closer-icon").click(function () {
    $('.nofication-popup').removeClass('d-flex');
    $('.nofication-popup').addClass('d-none');
});

// login popup when auth is false
$('.login-button').click(function () {
    $('.auth-popup').show();
});
$('.auth-close-button').click(function () {
    $('.auth-popup').hide();
    $('#booming2, #booming3').hide();
    $('#booming1').show();
});

// login auth and reset password popup content
$(".boom").click(function () {
    var $target = $($(this).data('target'));
    $(".booming").not($target).hide();
    $target.show();
});

// login popup close when click on body
$(document).on('click', function (e) {
    var loginButton = $(".login-button");
    var authPopup = $('.auth-popup');
    if (!$(e.target).closest(authPopup).length)
        if (!$(e.target).closest(loginButton).length) {
            authPopup.hide();
            $('.booming').hide();
            $('#booming1').show();
        }
});

// close when click dropdown
$('.btn-default').click(function () {
    $('.auth-popup').hide();
    $('.profile-popup').hide();
});
$('.dropdown-toggle').click(function () {
    $('.auth-popup').hide();
    $('.profile-popup').hide();
});
// auth popup close (droebit sanam iset kods davwert ro yvela popupi daikecos bgze dacherisas)
$(document).on('click', function (e) {
    var userName = $(".user-name ");
    var profilePopup = $('.profile-popup');
    if (!$(e.target).closest(userName).length)
        if (!$(e.target).closest(profilePopup).length) {
            profilePopup.hide();
            profilePopup.removeClass('d-block');
        }
});
// show episodes change (show, hide)
$(function () {
    $('#seasonselector').change(function () {
        $('.show-episodes').hide();
        $('#' + $(this).val()).show();
    });
});


// language selector and save
$(function () {
    if (window.location.href.indexOf("/en") > -1) {
        $(".language-selector").text("English");
        $(".english-language").hide();
        $(".language-img").attr("src", "/img/icons/united-states.svg");
    }
    if (window.location.href.indexOf("/ru") > -1) {
        $(".language-selector").text("Russian");
        $(".russian-language").hide();
        $(".language-img").attr("src", "/img/icons/russia.svg");
    }
    if (window.location.href.indexOf("/ge") > -1) {
        $(".georgian-language").hide();
    }
    $('.language-selector').removeClass('d-none')
});

// slider counter for mobile
var $status = $('.slider-count');
var $slickElement = $('.main-slider');

$slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    var i = (currentSlide ? currentSlide : 0) + 1;
    $status.text('0' + i + '/0' + slick.slideCount);
});

// only one checkbox
$(document).ready(function () {
    $('input:checkbox').click(function () {
        $('input:checkbox').not(this).prop('checked', false);
    });
});

// font size increase/decrease
(function ($) {
    $.fn.fontResize = function (options) {
        var increaseCount = 0;
        var self = this;
        var changeFont = function (element, amount) {
            var baseFontSize = parseInt(element.css('font-size'), 10);
            element.css('font-size', (baseFontSize + amount) + 'px');
        };
        options.increaseBtn.on('click', function (e) {
            e.preventDefault();
            if (increaseCount === 3) { return; }
            self.each(function (index, element) {
                changeFont($(element), 2);
            });
            increaseCount++;
        });
        options.decreaseBtn.on('click', function (e) {
            e.preventDefault();
            if (increaseCount === -2) { return; }
            self.each(function (index, element) {
                changeFont($(element), -2);
            });
            increaseCount--;
        });
    }
})(jQuery);

$(function () {
    $('.news-text').fontResize({
        increaseBtn: $('#text-increase'),
        decreaseBtn: $('#text-decrease')
    });
});

// valute changing nonstop
var i = 0;
var collection = $(".valute-switching");
var collection1 = $(".valute-mobile-switching");

var intervalId = window.setInterval(function () {
    $(collection).css('display', 'none');
    $(collection1).css('display', 'none');
    $(collection[i]).slideDown("slow");
    $(collection1[i]).slideDown("slow");
    i++
    i = i % collection.length;
    i = i % collection1.length;
}, 2500);

// print
$('.click-print').click(function () {
    window.print();
});


// quiz
$(".boom-test").click(function () {
    var $target = $($(this).data('target'));
    $(".booming-test").not($target).hide();
    $target.show();
});
// favorite
$(document).ready(function () {
    if (window.loggedIn == true) {
        $(".heart-svg").click(function () {
            $(this).closest('.add-favourite').children('.heart-svg').addClass('d-none');
            $(this).closest('.add-favourite').children('.red-heart-svg').removeClass('d-none');
        });
        // unfavorite
        $(".red-heart-svg").click(function () {
            $(this).closest('.add-favourite').children('.red-heart-svg').addClass('d-none');
            $(this).closest('.add-favourite').children('.heart-svg').removeClass('d-none');
        });
    }
});
$(".checkbox-disable").click(function () {
    $(".enable-button").attr("disabled", !this.checked);
    if ($(this).hasClass("true")) {
        $(this).closest('.booming-test').children('.right-answer').show();
        $(this).closest('.booming-test').children('.wrong-answer').hide();
        $(this).addClass('true-answer')
    }
    else {
        $(this).closest('.booming-test').children('.right-answer').hide();
        $(this).closest('.booming-test').children('.wrong-answer').show();
        $(this).addClass('false-answer');
        $(this).closest('.booming-test').find('.true').addClass('true-answer');
    }
});


$(".checkbox-disable").click(function () {
    $(this).closest(".booming-test").find('.checkbox-disable').attr('disabled', 'disabled');
});


// simple copy link
$('.detailed-copylink').click(function () {
    $(this).children('input.link-to-copy').select();
    document.execCommand("copy");
});
// curent date
$(document).ready(function () {
    var d = new Date();

    var month = d.getMonth() + 1;
    var day = d.getDate();

    var output = d.getFullYear() + '/' +
        (('' + month).length < 2 ? '0' : '') + month + '/' +
        (('' + day).length < 2 ? '0' : '') + day;
    $('.current-date').html(output)
});

let globalLoaderVisible = true;
function hideGlobalLoader() {
    if (globalLoaderVisible) {
        globalLoaderVisible = false;
        $('#overlay').fadeOut();
        $('body').removeClass('unscrolled-fixed');
    }
}
//when site is loaded, remove overlay and body fixed
$(document).ready(function () {
    // hide loader max 1s after document.ready
    setTimeout(function () {
        hideGlobalLoader();
    }, 1000);
});
$(window).on('load', function () {
    hideGlobalLoader();
});

// socialog popup
$(window).on('load', function () {
    if ($(window).width() < 767) {
        var secondsRemaining = 5;
        var i = setInterval(function () {
            secondsRemaining -= 1;
            if (secondsRemaining > 0) {
            } else {
                clearInterval(i);
                $(".socialog-popup-background").addClass('d-xs-flex')
            }
        }, 1000);
    }

});


// ads popup mobile
$(window).on('load', function () {
    if ($(window).width() < 767) {
        if ($(".ads-popup").hasClass("opened")) {
            $('body').addClass('unscrolled-fixed')
        }
    }
});

// close button ads countdown 
var secondsRemaining = 3;
function updateTime() {
    $(".ads-popup-timer").html(secondsRemaining);
}
updateTime();
var i = setInterval(function () {
    secondsRemaining -= 1;
    if (secondsRemaining > 0) {
        updateTime();
    } else {
        clearInterval(i);
        $('.mobile-popup-background').addClass('d-xs-none');
        $('.mobile-popup-background').removeClass('d-xs-flex');
        $('body').removeClass('unscrolled-fixed');
        $('.ads-popup').removClass('opened');
    }
}, 1000);



// once day show mobile full sceeen ads
// checks if one day has passed. 
// function hasOneDayPassed() {
//     // get today's date. eg: "7/37/2007"
//     var date = new Date().toLocaleDateString();
//     // if there's a date in localstorage and it's equal to the above: 
//     // inferring a day has yet to pass since both dates are equal.
//     if (localStorage.yourapp_date == date)
//         return false;
//     // this portion of logic occurs when a day has passed
//     localStorage.yourapp_date = date;
//     return true;
// }
// // some function which should run once a day
// function runOncePerDay() {
//     if (!hasOneDayPassed()) return false; {
//         if ($(window).width() < 767) {
//             $('.ads-popup').addClass('d-xs-flex');
//             $('.ads-popup').addClass('opened');
//         }
//     }
// };
// runOncePerDay(); // run the code
// runOncePerDay(); // does not run the code


//favorite ajax function
$(function () {
    $('.heart-svg').click(function () {
        favoriteFunction(this, 1);
    });
    $('.red-heart-svg').click(function () {
        favoriteFunction(this, 0);
    });
    function favoriteFunction(caller, favoriteUnfavorite) {
        var favoriteId = caller.getAttribute('favoriteable_id');
        var favoriteType = caller.getAttribute('favoriteable_type');
        $.ajax({
            type: "POST",
            headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
            url: `/${lang()}/favorite-toggle`,
            data: `toggle_favorite=${favoriteUnfavorite}&favoriteable_id=${favoriteId}&favoriteable_type=${favoriteType}`,
            success: function () {
            }
        });
    }
});

// Like ajax function
$(function () {
    $('.like').click(function () {
        likeFunction(this, 1);
    });
    $('.dislike').click(function () {
        likeFunction(this, 0);
    });
});

function likeFunction(caller, likeDislike) {
    var likeId = caller.getAttribute('likeable_id');
    var likeType = caller.getAttribute('likeable_type');
    $.ajax({
        type: "POST",
        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
        url: `/${lang()}/add-like`,
        data: `like_dislike=${likeDislike}&likeable_id=${likeId}&likeable_type=${likeType}`,
        success: function () {
        }
    });
}
$(function () {
    $('.like').click(function () {
        if (window.loggedIn == true) {
            var likedCount = parseInt($('.liked-count').text());
            var dislikedCount = parseInt($('.disliked-count').text());
            if ($(this).hasClass('liked')) {
            }
            if ($(this).hasClass('liked2')) {
                $('.liked-count').text(likedCount - 1);
                $(this).removeClass('liked2');
            }
            else {
                $('.liked-count').text(likedCount + 1);
                $(this).addClass('liked')
                $(this).addClass('liked2')
            }
            if ($('.dislike').hasClass('disliked2')) {
                $('.liked-count').text(likedCount + 1);
                $('.disliked-count').text(dislikedCount - 1);
                $('.dislike').removeClass('disliked2');
                $(this).addClass('liked2')
            }
            if ($(this).hasClass('liked2')) {
                $('.like-icon').addClass('text-light-blue')
                $('.dislike-icon').removeClass('text-light-blue')
            }
            else {
                $('.like-icon').removeClass('text-light-blue')
            }
        }
        else {
            notificationFunction();
            $('.notification-alert .alert-textarea').html('გთხოვთ გაიაროთ ავტორიზაცია')
        }
    });
    $('.dislike').click(function () {
        if (window.loggedIn == true) {
            var likedCount = parseInt($('.liked-count').text());
            var dislikedCount = parseInt($('.disliked-count').text());
            if ($(this).hasClass('disliked')) {
            }
            if ($(this).hasClass('disliked2')) {
                $('.disliked-count').text(dislikedCount - 1);
                $(this).removeClass('disliked2');
            }
            else {
                $('.disliked-count').text(dislikedCount + 1);
                $(this).addClass('disliked');
                $(this).addClass('disliked2')
            }
            if ($('.like').hasClass('liked2')) {
                $('.liked-count').text(likedCount - 1);
                $('.disliked-count').text(dislikedCount + 1);
                $('.like').removeClass('liked2');
                $(this).addClass('disliked2')
            }
            if ($(this).hasClass('disliked2')) {
                $('.dislike-icon').addClass('text-light-blue')
                $('.like-icon').removeClass('text-light-blue')
            }
            else {
                $('.dislike-icon').removeClass('text-light-blue')
            }
        }
        else {
            notificationFunction();
            $('.notification-alert .alert-textarea').html('გთხოვთ გაიაროთ ავტორიზაცია')
        }
    });
});
// Like ajax function

// validators
function notificationFunction() {
    $('.notification-alert').addClass('opened-notification')
    setTimeout(
        function () {
            $('.notification-alert').removeClass('opened-notification')
        },
        1500);
}
function successFunction() {
    $('.success-alert').addClass('opened-notification')
    setTimeout(
        function () {
            $('.success-alert').removeClass('opened-notification')
        },
        1500);
}
function failedFunction() {
    $('.failed-alert').addClass('opened-notification')
    setTimeout(
        function () {
            $('.failed-alert').removeClass('opened-notification')
        },
        1500);
}
function errorFunction() {
    $('.error-alert').addClass('opened-notification')
    setTimeout(
        function () {
            $('.error-alert').removeClass('opened-notification')
        },
        1500);
}
// add item favourites alerts
$(function () {
    $('.heart-svg').click(function () {
        if (window.loggedIn == true) {
            $('.notification-alert .alert-textarea').html('გამოწერა წარმატებით განახორციელეთ')
            notificationFunction();
        }
        else {
            $('.notification-alert .alert-textarea').html('გთხოვთ გაიაროთ ავტორიზაცია')
            notificationFunction();
        }
    });
    $('.red-heart-svg').click(function () {
        if (window.loggedIn == true) {
            $('.notification-alert .alert-textarea').html('გამოწერა წარმატებით გაუქმდა')
            notificationFunction();
        }
        else {
            $('.notification-alert .alert-textarea').html('გთხოვთ გაიაროთ ავტორიზაცია')
            notificationFunction();
        }
    });
});
// show popup only one time in day

$(window).on('load', function () {
    $('.subscribe-notification').addClass('opened-notification');
    setTimeout(
        function () {
            $('.subscribe-notification').removeClass('opened-notification')
        },
        15000);
    $('.subscribe-yes,.subscribe-no,.subscribe-close').click(function () {
        $('.subscribe-notification').removeClass('opened-notification')
    });
});

